<template>
    <setting-layout :title="$t('pages.user.setting.creator.withdraw_options.withdraw.title')">
        <v-container class="pt-2 mt-4">
            <router-link v-for="(item, index) in menus" :key="index" :to=" {name: item.to} "
                         class="link text-decoration-none">
                <v-row class="ml-0 mr-0 item pl-3 mt-3">
                    <v-col cols="10">
                        {{ item.text }}
                    </v-col>
                    <v-col>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6.711" height="12.913"
                             viewBox="0 0 6.711 12.913">
                            <path id="Контур_34833" data-name="Контур 34833" d="M10.794,4.9,5.371,0,0,4.9"
                                  transform="translate(5.961 1.06) rotate(90)" fill="none" stroke="#fff"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </svg>
                    </v-col>

                </v-row>
            </router-link>

        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
export default {
name: "Withdraw",
    computed: {
        menus() {
            return [
                //{text: 'Direct Transfer (OCT)',  to: 'user.setting.creator.withdraw.direct'},
                {text: this.$t('pages.user.setting.creator.withdraw_options.withdraw.wire_transfer.title'), to: 'user.setting.creator.withdraw.internationalWireTransfer'},
                {text: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.title'), to: 'user.setting.creator.withdraw.bank'},
                {text: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bitsafe.title'), to: 'user.setting.creator.withdraw.bitsafe'},
                {text: this.$t('pages.user.setting.creator.withdraw_options.withdraw.crypto_wallet.title'), to: 'user.setting.creator.withdraw.cryptoWallet'},
            ]
        }
    },
    components: {SettingLayout}
}
</script>

<style scoped>
.item {
    background: #1A1A1A;
    border-radius: 3px
}
</style>
